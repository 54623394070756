.pricing-card-title
{
    font-size: 1.2rem;
}
.plboton{
    color: #FFFFFF;
    background:#ff4432;
    border: none;
    font-size: 0.8rem;
    font-weight: bold;
    width: 8em;
}

.plboton:hover{
    color: #000000;
    background-color: #c8c8c8;
}

.plboton:focus{
    color: #000000;
    background-color: #c8c8c8; 
}

.contrataboton{
    background: #c8c8c8;
    font-size: 0.8rem;
    font-weight: bold;
    color: #FFFFFF;
    border:none;
    width: 8em;
}

.contrataboton:hover{
    background-color: #000000;
    color: #FFFFFF;
}

.contrataboton:focus{
    background-color: #000000; 
    color: #FFFFFF;
}

.plmedalla{
    width: 5rem;
    float: right;
    margin-right: -3.2rem;
}

.plicono{
    font-size: 1rem;
    margin-left: 1rem;
    color: #D8DAE2;
}
.coicono{
    font-size: 3rem;
    margin-left: 1rem;
    color: #D8DAE2;
}
.pcomparar{
    color:#79797c;
    margin: auto;
    font-style: italic;
}

.pscomparar{
    color: #ff4432;
    margin: auto;
    font-style: italic;
}

.pdivcomparar{
    align-items: center;
    display: flex;
    justify-content: center;
    height: 3rem;
    border: solid 1px #D8DAE2;
    cursor: pointer;
}

.psdivcomparar{
    align-items: center;
    display: flex;
    justify-content: center;
    height: 3rem;
    border: solid 1px #616163;
    cursor: pointer;    
}

.plparrafo{
    font-size: 0.8rem;
    white-space: pre-line;
    color: #ff4432;
    font-weight: bold;
}

/* .planesli{
    list-style-image: url(../imagenes/SeccionRenata/visto.png);
} */

.plparrafo2{
    font-size: 0.8rem;
    white-space: pre-line;
    color: #ff4432;
    font-weight: bold;
}

.pluf{
    font-size: 0.8rem;
}

.pejecutivo{
    color:#c8c8c8;
    font-style: italic;
    font-weight: bold;
    margin-top: -0.8rem;
}

.srplanes{
	position: relative;
}

.srplanes::after{
    content: "";
	width: 100%;
	height: 12%;
    background: #0061a0;
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
}

.srnseguro{
    color: white;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    z-index: 2;

}


/* Estilo para el header de las cards de planes */



.tooltip{
    background-color: #D8DAE2 !important;
    color: black !important;
}

/* formato a la tabla de comparacion  */
.compasistencia{
    align-items: center;
    display: flex;
    justify-content: center;
}

.cpasistencia{
    font-weight: bold;
    margin-bottom: 6rem;
    color: black;
}

.cpcobertura{
    color: black;
    font-weight: bold;
    margin-bottom: 3rem;
}

.ctr{
    background: #ff4432;
    color: white;
}

.cnombreseguro{
    height: 50px;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
}

.cvalorprima{
    color: grey;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.cvalorseguro{
    color: black;
    margin-bottom: 0.2;
}

.icoinfo{
    font-size: 1rem;
    color: black;
}

.cdivicon{
    margin-bottom: 2rem;
    margin-top: 1rem;
}

.cdivicoinfo{
    margin-left: 5rem;
    margin-top: -4rem;
}

.cpclinicas{
    color: #0061a0;
    cursor: pointer;
}

.btndescargar{
    background: #0061a0;
    color: white;
    width: 50%;
}

.btndescargar:hover{
    color: white;
    background: #0061a0;
}

.modal-backdrop.fade.show{
    opacity: 0.02;
}

.cpbtncerrar{
    cursor: pointer;
    color: #ff4432;
    height: 200px;
width: 50%;
}

.cpbtncerrar:hover{
    background: #f2f2f2;
    transition: .3s ease all;
    border-radius: 5px;
}

.rcorners1 {
  border-radius: 90px;
}

.rounded-top-left-1 {
    border-top-left-radius: 1rem;
}
.rounded-top-right-1 {
    border-top-right-radius: 1rem;
}
.rounded-bottom-left-1 {
    border-bottom-left-radius: 1rem;
}
.rounded-bottom-right-1 {
    border-bottom-right-radius: 1rem;
}

/* Estilos a pantallas pequeñas menores de 1024px*/

@media (max-width: 1024px) {

}


/* Estilos a pantallas pequeñas menores de 768px*/

@media (max-width: 768px) {

}


/* Estilos a pantallas pequeñas menores de 600px*/

@media (max-width: 600px) {
    .plcol{
        margin-bottom: 2rem;
    }
}
