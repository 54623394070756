
#jn{
    margin-left: 2rem;
    margin-right: 2rem;
}


.jntitulos{
    font-weight: bold;
    margin-top: 2rem;
    margin-left: 1.2rem;
    margin-right: 1.2rem;
}

.jncard{

    border-width: 2px;
    transition: 0.5s;
}

#jnimg1{
    width: 9rem;
    margin-top: 0.5rem;
}

#jnimg2{
    width: 10rem;
}

#jnimg3{
    width: 10rem;
    margin-top: 1rem;

}

#jnimg4{
    width: 10rem;
    margin-top: 1rem;
}

#cflogo{
    width: 4rem;
}

/* added */
@media (max-width: 768px) { 
    #jn section > .row > .col{ margin-bottom: 20px; }
}


        /* Estilos a pantallas pequeñas menores de 600px*/

@media (max-width: 600px) {
    
    .jntitulos{
        font-size: 0.63rem;
    }

    #jnimg1{
        width: 5rem;
    }

    #jnimg2{
        width: 5rem;
    }

    #jnimg3{
        width: 5rem;
    }

    #jnimg4{
        width: 6rem;
    }

  }

        /* Estilos a pantallas pequeñas menores de 321px*/

  @media (max-width: 321px) {
    
    #jnimg1{
        width: 5rem;
    }

    #jnimg2{
        width: 5rem;
    }

    #jnimg3{
        width: 4rem;
    }

    .jntitulos{
        font-size: 0.60rem;
    }

}

