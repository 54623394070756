#ft,#ftlegal{
    background: #EDEDED;
    /*height: 20rem;*/
    color: #808080;
    padding-bottom: 1rem;
    /* margin-top: 2rem; */
    /* added */
}

.cfparrafo h6{
    line-height: 1.3rem;
    margin: 1rem;
}


.fdivlogos{
    display: flex;
    justify-content: space-between;
    margin-left: 3rem;
    margin-right: 3rem;
}

.flogo1{
    width: 13rem;
}

.flogo2{
    width: 10rem;
}

.fdivenlaces{
    margin-left: 4rem;
    line-height: .2; 
}

.fdivenlace{
    display: flex; 
    margin-top: 5rem;
    font-size: 12px;
    color: #0061a0;
}

.ficono{
    margin-left: 0.7rem;
    margin-right: 0.7rem;
    font-size: 0.4rem;
    color: white;
}

.fdivp1{
    display: flex;
}

.fdivp2{
    display: flex;
}

.fenlaces{
    text-decoration: none;
}

a.fenlaces{
    color:white;
    font-weight: bold;
}

.fdivinfo{
    font-size: 12px;
}

.fdivenlacesinternos{
    display: flex;
    margin-top: 3rem;
}


        /* Estilos a pantallas pequeñas menores de 600px*/

@media (max-width: 600px) {

    .fdivlogos{
        margin-left: 1rem;
        margin-right: 1rem;
    }
    
    .flogo1{
        width: 8rem;
    }
    
    .flogo2{
        width: 8rem;
    }


.footer{
    margin-left: 0rem;
    display: inline;
}
.fdivlogo{
    margin-top: 1rem;
    text-align: center;
}

.fdivenlaces{
    margin-left: 0.5rem;
    line-height:inherit;
    margin-top: -3rem;
}

.fdivenlace{
    display: inline;
    font-size: 11px;
    line-height: 1.2;
}

.fdivp1{
    margin-top: 4rem;
}

.fdivinfo{
    font-size: 10px;
    line-height: 1.5;
    width: 95%;
}

.fdivenlacesinternos{
    font-size: 10.5px;
    margin-top:0;

}

.fdivtextolegal{
    font-size: 12.5px;
    
    text-align: center;

}


.ficono{
    margin-right: 0.1rem;
}

}

/* added */
@media (max-width: 767.98px){
    .fdivenlace {
        flex-direction: column;
    }

    .fdivlogos{
        margin-left: 1rem;
        margin-right: 1rem;
    }
    
    
    .flogo1{
        width: 8rem;
    }
    
    .flogo2{
        width: 8rem;
    }
    
}


