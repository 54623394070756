
#bcsection1{
    margin-left: 5rem;
}
/* Added */
#bcsection1 h4{ line-height: 1.5; text-align: justify; }

#bccard{
    background: #ff4432;
    color:#ffffff;
}

.bcparrafos{
    background-color: #f5675d;
    border-radius: 20px;
    border: 1px solid white;
    width: 400px;
    height: 130px;
    display: flex;
    margin-right: 1rem;
    
} 

.cfparrafo2{
    text-align: right;
}

.bctexto{
    margin: auto;
    text-align: center;
} 

.bccontainer1{
    display: flex;
    display: -webkit-flex;
    text-align: center;
    justify-content: center; 
}

.bccontainer2{
    display: flex;
    display: -webkit-flex;
    text-align: center;
    justify-content: center;
}



        /* Estilos a pantallas pequeñas menores de 600px*/

@media (max-width: 600px) {
    #bcsection1 h4{font-size: 0.6rem;}
    #bccard{
        font-size: 0.6rem;
    }

    .bctexto h4{font-size: 0.6rem;}
}

    
        /* Estilos a pantallas pequeñas menores de 414px*/

@media (max-width: 415px) {


    #bcsection1 h4{margin-top: 1rem;font-size: 0.625rem;}
    #bccard{
    margin-top: 1rem;
    font-size: 0.625rem;
    }

    .bctexto h4{font-size: 0.6rem;}
    
}


        /* Estilos a pantallas pequeñas menores de 412px*/

@media (max-width: 412px) {
    #bcsection1 h4{margin-top: 1rem;font-size: 0.625rem;}
    #bccard{
    margin-top: 1rem;
    font-size: 0.625rem;
    }

    .bctexto h4{font-size: 0.6rem;}

}

        /* Estilos a pantallas pequeñas menores de 380px*/ 

@media (max-width: 380px) {
    #bcsection1 h4{font-size: 0.55rem;}
    #bccard{
        font-size: 0.55rem;
    }

    .bctexto h4{font-size: 0.6rem;}
}

        /* Estilos a pantallas pequeñas menores de 361px*/

@media (max-width: 361px) {
    #bcsection1 h4{font-size: 0.45rem;}
    #bccard{
        font-size: 0.4rem;
    }

    .bctexto h4{font-size: 0.6rem;}
 
}

        /* Estilos a pantallas pequeñas menores de 321px*/

@media (max-width: 321px) {
    #bcsection1 h4{font-size: 0.32rem;line-height: 0.8rem;}     
    #bccard{
        font-size: 0.32rem;
        line-height: 0.8rem;
    }

    .bctexto h4{font-size: 0.6rem;}

}
