

#inicio{
    background-image: url(../imagenes/Home/home3.png);
    width:inherit;
    height: 60vh; 
    text-align: center;
    background-position: center 25px;
    background-size:contain;
    background-repeat: no-repeat;   
}

.inav{
    max-height: 180px;
}

#ilogo{
    width: 12rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 4rem;
}

.navbar-brand {
    margin-right: 15rem;
}

.navbar-light .navbar-nav .nav-link{
    color:#000000;
    font-size: 0.8rem;
    margin-left: 1rem;
    margin-top: 1.2rem;
}

.navbar-light .navbar-nav .nav-link{
    position: relative;
    overflow: hidden;
    display: inline-block;
    text-decoration: none;
    color: #333;
}

.navbar-light .navbar-nav .nav-link:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: #333;
    transition: left .8s;
}

.navbar-light .navbar-nav .nav-link:hover:after {
    left: 0;
}


#barraenlace{
    background:#ffffff;
}

.navbar{
    background:#ffffff;
    transition: all 0.6s;
}

#parrafo{
    justify-content: right;
    /*margin-top: 9rem;
    margin-right: 4rem;*/
    height: 45vh;
    overflow: hidden;
}

#parrafo > div{ align-self: center !important; }

#ip1{
    font-size: 2.3rem;
    color: white;
    font-weight: bold;
    /*width: 40rem;
    margin-left: -6rem;*/
}

.ip1texto{
  /*  color:#a4ce4e; */
  color:white
}

#ip2{
    color: white;
    font-size: 3.2rem;
    font-weight: bold;
   /* width: 40rem;
    margin-left: -6rem;*/
    margin-top: 2rem;
    margin-bottom: 2rem;
}

#ip3{
    color:white;
    font-weight: bold;
    margin-bottom: 1rem;
    margin-top: 2rem;
    /*margin-left: -11rem;*/
}

.itarjetas{
    width: 35rem;
    /*width: 26rem;*/
    margin-top: 0rem;
    /*margin-left: -11rem;*/
}


.botonInicio{
    color: black;
    background: white;
    border-color:white;
    margin: 2rem 0;
    font-weight: bold;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    overflow: hidden;
    /*margin-left: -11rem;*/
}

.botonInicio:hover{
    background: white;
    border-left: white;
    border-right: white;
    border-top: white;
    color: #ff4432;
}

.botonInicio:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: #ff4432;
    transition: left .8s;
    }

.botonInicio:hover:after {
    left: 0;
    }

/* Estilo para capsula amarilla */
  .inline-block {
      display: inline-block;
  }

  .bg-brand-yellow {
      --tw-bg-opacity: 1;
      background-color: rgba(255,201,19,var(--tw-bg-opacity));
  }
.capsula{
    border-radius: 75px;
    font-weight: 700;
    line-height: 1;
    border-radius: 3.25rem;
    /*white-space: nowrap;*/
    padding: 0.20em 0.30em
  }

  .bg-brand-grey {
      --tw-bg-opacity: 1;
      background-color: rgba(224,233,242,var(--tw-bg-opacity));
  }
  .capsulaTarjeta{
      border-radius: 75px;
      font-weight: 200;
      line-height: 0;
      border-radius: 3.25rem;
      border: 2.5px solid white;
      background: #ffbfbb ;
      padding: 1em 0.35em;
    }
    /* added */
    @media (max-width: 340px){
        #inicio{
            background-image: url(../imagenes/Home/homemobile3.png);
            background-position: center 90px;
            background-size:contain;
            height: 40vh; 
        }
        #barraenlace{
            min-height: 50px;
        }        
    }
    @media (min-width: 340.02px) and (max-width: 375.98px){
        #inicio{
            background-image: url(../imagenes/Home/homemobile3.png);
            background-position: center 90px;
            background-size:contain;
            height: 45vh; 
        }
        #ip2{
            font-size: 2.2rem;
        }        
        #barraenlace{
            min-height: 30px;
        }
    }
    @media (min-width: 376px) and (max-width: 390px){
        #inicio{
            background-image: url(../imagenes/Home/homemobile3.png);
            background-position: center 90px;
            background-size:contain;
            height: 40vh; 
        }
        #barraenlace{
            min-height: 30px;
        }       
    }
    @media (min-width: 390.02px) and (max-width: 500px){
        #inicio{
            background-image: url(../imagenes/Home/homemobile3.png);
            background-position: center 90px;
            background-size:contain;
            height: 35vh; 
        }
        .navbar-brand{ margin-right: 0;}
        #barraenlace{
            min-height: 30px;
        } 
    }
    @media (min-width: 500.02px) and (max-width: 830px){
        #inicio{
            background-image: url(../imagenes/Home/homemobile3.png);
            background-position: center 50px;
            background-size:contain;
            height: 50vh; 
        }
        .navbar-brand{ margin-right: 0;}
        #barraenlace{
            min-height: 30px;
        } 
    }
    @media (min-width: 830.02px) and (max-width: 991.98px){
        #inicio{
            background-image: url(../imagenes/Home/home3.png);
            background-position: center 50px;
            background-size:contain;
            height: 30vh; 
        }
        .navbar-brand{ margin-right: 0;}
        #barraenlace{
            min-height: 30px;
        } 
    }

    @media (min-width: 992px) and (max-width: 1199.98px) { 
        #inicio{
            background-image: url(../imagenes/Home/home3.png);
            background-position: center 25px;
            background-size:contain;
            height: 30vh; 
        }
        #ip1{
            font-size: 1.7rem;
        }
        #ip2{
            font-size: 2.2rem;
        }
        .itarjetas{            
            width: 26rem;            
        }
        .navbar-light .navbar-nav .nav-link{ font-size: 0.6rem; }
        
    }
    @media (min-width: 1200px) and (max-width: 1299.99px) {
        #ip2{
            font-size: 2.8rem;
        }
    }